import React from 'react';

import { useTitle } from 'shared/hooks/useTitle';

import './style.scss';


interface INotFoundPageProps {
    children: React.ReactNode;
}

export const NotFoundPage = ({children}: INotFoundPageProps) => {
    useTitle('Not Found');

    return (
        <>
            <div className="NotFoundPage">
                <h1 className="NotFoundPage__title">Page not found</h1>
                <p className="NotFoundPage__description">We could not find the page you're looking for</p>
                <div className="NotFoundPage__description">
                    {children}
                </div>
            </div>
        </>
    );
};

NotFoundPage.defaultProps = {
    footer: true,
};