import React from 'react';

import { Button, Form, Table } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';

import { IDomain } from '../../shared/modules/domain/models';
import { domainListSelector } from '../../shared/modules/domain/state/domain-list';

import { CreateTenantDomainModal } from './CreateTenantDomainModal';
import { DomainListRow } from './DomainListRow';
import { DeleteDomainModal } from './DeleteDomainModal';

interface IDomainListProps {
    tenantId: number;
}

export const DomainList = ({tenantId}: IDomainListProps) => {
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = React.useState<IDomain | undefined>(undefined);
    const {domains} = useRecoilValue(domainListSelector({
        tenantId,
        limit: 100,
    }));

    return (
        <>
            <Form.Group>
                <Form.Label className="mb-0">Tenant Domains</Form.Label>
                <Form.Text>Manage the domains from which you can access the tenant.</Form.Text>
            </Form.Group>
            {!!domains.length ? (
                <Table size="sm" bordered>
                    <thead>
                    <tr>
                        <th>
                            Host
                        </th>
                        <th>
                            Is Enabled
                        </th>
                        <th>
                            Options
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {domains.map(domain => (
                        <DomainListRow key={domain.id} domain={domain} onDelete={() => setShowDeleteModal(domain)}/>
                    ))}
                    </tbody>
                </Table>
            ) : (
                <div className="text-center text-muted">
                    There are no domains
                </div>
            )}
            <Button variant="primary" onClick={() => setShowModal(true)}>
                Add Domain
            </Button>
            <CreateTenantDomainModal
                tenantId={tenantId}
                show={showModal}
                onHide={() => setShowModal(false)}
            />
            {showDeleteModal && (
                <DeleteDomainModal
                    domain={showDeleteModal}
                    show={!!showDeleteModal}
                    onHide={() => setShowDeleteModal(undefined)}
                />
            )}
        </>
    );
};
