import React from 'react';

import { Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { useTitle } from 'shared/hooks/useTitle';

export const Dashboard = () => {
    useTitle('Dashboard');

    return (
        <Container>
            <h1>Admin Dashboard</h1>
            <div className="mt-3">
                <h5>Quick Actions</h5>
                <Button as={Link} to="/tenants/create" variant="primary">Create Tenant</Button>
                <p>
                    <Link to="/tenants">Manage Tenants</Link>
                </p>
            </div>
        </Container>
    );
};