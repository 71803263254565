import { atom, DefaultValue, selectorFamily } from 'recoil';

import { ITenant } from '../models';
import { readTenant } from '../api';

export const tenantAtom = atom<{ [key: number]: ITenant | undefined }>({
    key: 'tenantAtom',
    default: {},
});

export const tenantSelector = selectorFamily<ITenant, number>({
    key: 'tenantSelector',
    get: (tenantId: number) => async ({get}): Promise<ITenant> => {
        const tenantsInStore = get(tenantAtom);
        return tenantsInStore[tenantId] ?? await readTenant(tenantId);
    },
    set: (tenantId: number) => ({get, set}, newValue) => {
        const tenantsInStore = get(tenantAtom);
        const newTenantsInStore = Object.assign({}, tenantsInStore);
        newTenantsInStore[tenantId] = newValue instanceof DefaultValue ? undefined : newValue;
        set(tenantAtom, newTenantsInStore);
    },
});