import React from 'react';

import { Config } from 'config';

export const useTitle = (title?: string, overrideBaseTitle?: boolean) => {

    const appName = React.useMemo(() => {
        return Config.appName ?? 'Cloudschool';
    }, []);

    React.useEffect(() => {
        if (!title) {
            document.title = appName;
        } else {
            document.title = overrideBaseTitle ? title : `${title} - ${appName}`;
        }
    }, [appName, title, overrideBaseTitle]);
};
