import React from 'react';

import { Dropdown } from 'react-bootstrap';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ITableRowDropdownProps {
    id: string;
    children: React.ReactNode;
}

export const TableRowDropdown = ({id, children}: ITableRowDropdownProps) => {

    return (
        <Dropdown>
            <Dropdown.Toggle size="sm" variant="secondary" id={id} className="table__button">
                <FontAwesomeIcon icon={faEllipsisH} size="lg"/>
            </Dropdown.Toggle>

            <Dropdown.Menu align="right">
                {children}
            </Dropdown.Menu>
        </Dropdown>
    );
};
