import { atom, selector } from 'recoil';

import { ITenantInfo } from '../models';
import { Config } from 'config';
import { readTenantInfo } from '../api';
import { guardRecoilDefaultValue } from 'shared/utils/recoil-utils';
import { removeTenantInfoLocalStorage, setTenantInfoLocalStorage } from '../utils';

export const tenantInfoAtom = atom<ITenantInfo | undefined>({
    key: 'tenantInfoAtom',
    default: undefined,
});

export const tenantInfoSelector = selector<ITenantInfo>({
    key: 'tenantInfoSelector',
    get: async ({get}): Promise<ITenantInfo> => {
        const tenantInfoInStore = get(tenantInfoAtom);
        if (tenantInfoInStore) {
            return tenantInfoInStore;
        } else if (Config.tenantId) {
            return await readTenantInfo({
                tenant_id: Config.tenantId,
            });
        } else {
            return await readTenantInfo({
                host: window.location.host,
            });
        }
    },
    set: ({get, set}, newValue) => {
        const newTenantInfo = guardRecoilDefaultValue(newValue) ? undefined : newValue;
        set(tenantInfoAtom, newTenantInfo);
        if (newTenantInfo) {
            setTenantInfoLocalStorage(newTenantInfo);
        } else {
            removeTenantInfoLocalStorage();
        }
    },
});

export const tenantInfoReadSelector = selector<ITenantInfo>({
    key: 'tenantInfoReadSelector',
    get: async (): Promise<ITenantInfo> => {
        if (Config.tenantId) {
            return await readTenantInfo({
                tenant_id: Config.tenantId,
            });
        } else {
            return await readTenantInfo({
                host: window.location.host,
            });
        }
    },
});

export const tenantIdSelector = selector<number>({
    key: 'tenantIdSelector',
    get: ({get}) => {
        const tenantInfo = get(tenantInfoAtom);
        if (!tenantInfo) {
            throw new Error('Tenant not set');
        }
        return tenantInfo.id;
    },
});
