export const getAuthorizationHeader = (accessToken: string) => {
    return `Bearer ${accessToken}`;
};

export const getAxiosOptions = (accessToken: string) => {
    return {
        headers: getAxiosHeaders(accessToken),
    };
};

export const getAxiosHeaders = (accessToken: string) => {
    return {
        'Authorization': getAuthorizationHeader(accessToken),
    };
};
