import { useEffect } from 'react';

import { useSetRecoilState } from 'recoil';

import { navbarState } from 'shared/state/navbar';

export const useNavbar = (displayNavbar: boolean) => {
    const setDisplayNavbar = useSetRecoilState(navbarState);

    useEffect(() => {
        setDisplayNavbar(displayNavbar);
    }, [setDisplayNavbar, displayNavbar]);
};
