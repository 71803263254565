import React, { useState } from 'react';

import { Button, Container, Form, InputGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

import { useGuaranteedSystemAuth } from 'shared/hooks/useAuth';
import { getAxiosErrorOrThrow } from 'shared/utils/error';
import { useTitle } from 'shared/hooks/useTitle';
import { Config } from '../../config';

type CreateTenantFormData = {
    name: string;
    firstname: string;
    lastname: string;
    host: string;
    email: string;
    username: string;
    phone_number: string;
    password: string;
    timezone: string;
};

export const CreateTenantPage = () => {
    useTitle('Create Tenant');
    const {getAccessTokenSilently} = useGuaranteedSystemAuth();
    const {register, handleSubmit, reset} = useForm<CreateTenantFormData>({
        defaultValues: {
            name: 'qwe',
            host: 'qwe',
            firstname: 'qwe',
            lastname: 'qwe',
            username: 'qwe',
            email: 'qwe@qwe.com',
            password: 'qwe',
            timezone: 'Australia/NSW',
            phone_number: '+61444444444',
        },
    });
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const navigate = useNavigate();

    const onSubmit = handleSubmit((formData) => {
        if (isSubmitting) {
            return;
        } else {
            setIsSubmitting(true);
        }
        (async () => {

            const payload = Object.assign(formData);
            payload.hosts = [`${payload.host}.${Config.publicHost}`];
            delete payload.host;

            try {
                const accessToken = await getAccessTokenSilently();
                const response = await axios.post(
                    `${Config.tenantServiceURL}/tenants/`,
                    payload,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    },
                );
                window.open(response.data.login_url, '_blank');
                reset();
                toast.success('Account created successfully.');
                navigate(`/tenants/${response.data.tenant.id}`);
            } catch (e) {
                const errorMessage = getAxiosErrorOrThrow(e);
                toast.error(errorMessage);
            }

            setIsSubmitting(false);
        })();
    });

    return (
        <Container>
            <h1>Create Tenant</h1>
            <Form onSubmit={onSubmit}>
                <Form.Group controlId="CreateTenant.name">
                    <Form.Label>Business Name</Form.Label>
                    <Form.Control
                        type="text"
                        disabled={isSubmitting}
                        autoFocus
                        {...register('name')}
                    />
                </Form.Group>
                <Form.Group controlId="CreateTenant.host">
                    <Form.Label>Tenant Domain</Form.Label>
                    <InputGroup className="mb-3">
                        <Form.Control
                            type="text"
                            placeholder="E.g. 'business-name'"
                            disabled={isSubmitting}
                            aria-label="Tenant subdomain"
                            aria-describedby="CreateTenant__host-addon"
                            {...register('host')}
                        />
                        <InputGroup.Append>
                            <InputGroup.Text id="CreateTenant__host-addon">.{Config.publicHost}</InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </Form.Group>
                <Form.Group controlId="CreateTenant.firstname">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                        type="text"
                        disabled={isSubmitting}
                        {...register('firstname')}
                    />
                </Form.Group>
                <Form.Group controlId="CreateTenant.lastname">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                        type="text"
                        disabled={isSubmitting}
                        {...register('lastname')}
                    />
                </Form.Group>
                <Form.Group controlId="CreateTenant.username">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                        type="text"
                        disabled={isSubmitting}
                        {...register('username')}
                    />
                </Form.Group>
                <Form.Group controlId="CreateTenant.email">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                        type="text"
                        disabled={isSubmitting}
                        {...register('email')}
                    />
                </Form.Group>
                <Form.Group controlId="CreateTenant.phone_number">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                        type="text"
                        disabled={isSubmitting}
                        {...register('phone_number')}
                    />
                </Form.Group>
                <Form.Group controlId="CreateTenant.password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type="password"
                        {...register('password')}
                        disabled={isSubmitting}
                    />
                </Form.Group>
                <Form.Group controlId="CreateTenant.timezone">
                    <Form.Label>Business Timezone</Form.Label>
                    <Form.Control
                        type="text"
                        {...register('timezone')}
                        disabled={isSubmitting}
                    />
                </Form.Group>
                <Button type="submit" variant="primary">Create</Button>
                <Button as={Link} variant="secondary" to="/tenants">Cancel</Button>
            </Form>
        </Container>
    );
};
