import axios from 'axios';

import {getAccessTokenSilently} from 'shared/utils/token';
import {getAxiosOptions} from 'shared/utils/api';
import {IUserCreate, IUpdateUserInput, IUser, IUserListResult, IUserListQuery} from '../models/user';
import {Config} from 'config';

export const userProfilePictureSize = 600;

export const getFullName = ({firstname, lastname}: { firstname: string, lastname: string }) => {
    return `${firstname} ${lastname}`;
};

export const getURLForUserService = (tenantId: number, path: string) => {
    return `${Config.userServiceURL}/tenants/${tenantId}/users${path}`;
};

export async function readUser(tenantId: number, userId: number): Promise<IUser> {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getURLForUserService(tenantId, `/${userId}`), getAxiosOptions(accessToken));
    return response.data;
}

export async function readUserList({tenantId, ...params}: IUserListQuery): Promise<IUserListResult> {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getURLForUserService(tenantId, '/'), {
        params,
        ...getAxiosOptions(accessToken),
    });
    return response.data;
}

export async function updateUser({tenantId, userId, updateData}: IUpdateUserInput): Promise<IUser> {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.patch(
        getURLForUserService(tenantId, `/${userId}`),
        updateData,
        getAxiosOptions(accessToken),
    );
    return response.data;
}

export async function createUser(tenantId: number, userData: IUserCreate): Promise<IUser> {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getURLForUserService(tenantId, '/'), userData, getAxiosOptions(accessToken));
    return response.data;
}
