import React from 'react';

import { Badge, Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { IDomain } from '../../shared/modules/domain/models';
import { updateDomain } from '../../shared/modules/domain/api';
import { getAxiosErrorOrThrow } from '../../shared/utils/error';

import { TableRowDropdown } from '../../shared/components/TableRowDropdown/TableRowDropdown';

interface IDomainListRowProps {
    domain: IDomain;
    onDelete: () => void;
}

export const DomainListRow = ({domain, onDelete}: IDomainListRowProps) => {
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

    const onToggleDisabled = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        setIsSubmitting(true);
        (async () => {
            try {
                const newIsDisabled = !domain.is_disabled;
                await updateDomain({
                    tenantId: domain.tenant_id,
                    domainId: domain.id,
                    updateData: {
                        is_disabled: newIsDisabled,
                    },
                });
                const message = newIsDisabled ? 'Domain disabled' : 'Domain enabled';
                toast.success(`${message}. Please refresh the page.`);
            } catch (e) {
                toast.error(getAxiosErrorOrThrow(e));
            } finally {
                setIsSubmitting(false);
            }
        })();
    };

    return (
        <tr>
            <td>{domain.host}</td>
            <td>
                <Badge variant={domain.is_disabled ? 'danger' : 'success'}>
                    {domain.is_disabled ? 'Disabled' : 'Enabled'}
                </Badge>
            </td>
            <td>
                <TableRowDropdown id={`DomainListRow.${domain.id}`}>
                    {domain.is_disabled ? (
                        <Dropdown.Item onClick={onToggleDisabled} disabled={isSubmitting}>
                            Enable
                        </Dropdown.Item>
                    ) : (
                        <Dropdown.Item onClick={onToggleDisabled} disabled={isSubmitting}>
                            Disable
                        </Dropdown.Item>
                    )}
                    <Dropdown.Divider/>
                    <Dropdown.Item onClick={onDelete}>
                        Delete
                    </Dropdown.Item>
                </TableRowDropdown>
            </td>
        </tr>
    );
};
