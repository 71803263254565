import React from 'react';

import { Navigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAuth } from 'shared/hooks/useAuth';
import {Scope} from 'shared/modules/auth/models';

import { LoadingPageFallback } from 'shared/components/LoadingPage/LoadingPageFallback';

interface IRequireAuthProps {
    redirect: string;
    redirectScopes: string;
    scopes?: Scope[];
    fallback?: React.ReactNode;
}

export const RequireAuth = ({scopes, redirect, redirectScopes, fallback, children}: React.PropsWithChildren<IRequireAuthProps>) => {
    const {isAuthenticated, tokenData, isLoading} = useAuth();
    const hasShownAuthNotificationRef = React.useRef<boolean>(false);

    const { pathname } = useLocation();

    const isCorrectScopes = (!scopes || (tokenData && scopes.every(scope => tokenData.scopes.includes(scope))));

    const spinner = (
        <LoadingPageFallback/>
    );

    if (isLoading) {
        return spinner;
    } else if (isAuthenticated && isCorrectScopes) {
        return (
            <React.Suspense fallback={spinner}>
                {children}
            </React.Suspense>
        );
    } else if (fallback) {
        return (
            <>{fallback}</>
        );
    } else {
        let redirectToUse = (isAuthenticated && !isCorrectScopes) ? redirectScopes || redirect : redirect;
        if (redirectToUse === '/login') {
            redirectToUse += `?redirect=${pathname}`;
        }
        if (isAuthenticated && !hasShownAuthNotificationRef.current) {
            hasShownAuthNotificationRef.current = true;
            toast.warning('You do not have permission to view that page.');
        }
        return (
            <Navigate to={{pathname: redirectToUse}}/>
        );
    }
};

RequireAuth.defaultProps = {
    redirect: '/login',
    redirectScopes: '/',
};
