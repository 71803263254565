import React from 'react';

import { authContext, useAuthProvider } from 'shared/hooks/useAuth';

interface IAuthProviderProps {
    tenantId: number | null;
    children?: React.ReactNode;
}

export function AuthProvider({children, tenantId}: IAuthProviderProps) {
    const auth = useAuthProvider({
        tenantId,
    });

    return (
        <authContext.Provider value={auth}>{children}</authContext.Provider>
    );
}