import React from 'react';

import classNames from 'classnames';

interface IFullScreenModalTitleProps {
    className?: string;
}

export const FullScreenModalTitle = ({children, className}: React.PropsWithChildren<IFullScreenModalTitleProps>) => {
    return (
        <h1 className={classNames(className, 'FullScreenModal__title')}>{children}</h1>
    );
};
