import React from 'react';

import { Button, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { IDomain } from '../../shared/modules/domain/models';
import { deleteDomain } from '../../shared/modules/domain/api';
import { getAxiosErrorOrThrow } from '../../shared/utils/error';

interface IDeleteDomainModalProps {
    domain: IDomain;
    show: boolean;
    onHide: () => void;
}

export const DeleteDomainModal = ({domain, onHide, show}: IDeleteDomainModalProps) => {
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        setIsSubmitting(true);
        (async () => {
            try {
                await deleteDomain({
                    tenantId: domain.tenant_id,
                    domainId: domain.id,
                });
                onHide();
                toast.success('Domain deleted successfully! Please refresh the page.');
            } catch (e) {
                toast.error(getAxiosErrorOrThrow(e));
            } finally {
                setIsSubmitting(false);
            }
        })();
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header>
                <Modal.Title>Create Domain</Modal.Title>
            </Modal.Header>
            <Form onSubmit={onSubmit}>
                <Modal.Body>
                    <p>Are you sure you want to delete this domain?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="danger" disabled={isSubmitting}>
                        Yes, delete domain
                    </Button>
                    <Button variant="secondary" onClick={onHide} disabled={isSubmitting}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};