import axios from 'axios';

import {
    IDomain,
    IDomainCreate,
    IDomainDelete,
    IDomainListQuery,
    IDomainListResult,
    IDomainUpdateProps,
} from '../models';
import {getAccessTokenSilently} from 'shared/utils/token';
import {getAxiosHeaders, getAxiosOptions} from 'shared/utils/api';
import {Config} from 'config';

export const getURLForDomainAPI = (tenantId: number, path: string) => {
    return `${Config.tenantServiceURL}/tenants/${tenantId}/domains${path}`;
};

export const readDomainList = async ({tenantId, ...params}: IDomainListQuery): Promise<IDomainListResult> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getURLForDomainAPI(tenantId, '/'), {
        params,
        headers: getAxiosHeaders(accessToken),
    });
    return {
        domains: response.data?.domains ?? [],
        nextCursor: response.data?.next_cursor,
        more: response.data?.more,
    };
};

export async function createDomain({tenantId, ...domainCreate}: IDomainCreate): Promise<IDomain> {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getURLForDomainAPI(tenantId, '/'), domainCreate, getAxiosOptions(accessToken));
    return response.data;
}

export async function deleteDomain({tenantId, domainId}: IDomainDelete) {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.delete(getURLForDomainAPI(tenantId, `/${domainId}`), getAxiosOptions(accessToken));
    return response.data;
}

export async function updateDomain({tenantId, domainId, updateData}: IDomainUpdateProps) {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.put(
        getURLForDomainAPI(tenantId, `/${domainId}`),
        updateData,
        getAxiosOptions(accessToken),
    );
    return response.data;
}
