import React from 'react';

import { Link, Route, Routes } from 'react-router-dom';

import { useSystemAuth } from 'shared/hooks/useAuth';
import { setAccessTokenSilently } from 'shared/utils/token';

import { Spinner } from 'shared/components/Spinner/Spinner';
import { Navbar } from 'components/Navbar/Navbar';
import { AuthLogin } from 'components/AuthLogin/AuthLogin';
import { Dashboard } from 'pages/Dashboard/Dashboard';
import { CreateTenantPage } from 'pages/CreateTenantPage/CreateTenantPage';
import { TenantsPage } from 'pages/TenantsPage/TenantsPage';
import { ViewTenantPage } from 'pages/ViewTenantPage/ViewTenantPage';
import { NotFoundPage } from 'pages/NotFoundPage/NotFoundPage';
import { UpdateTenantPage } from 'pages/UpdateTenantPage/UpdateTenantPage';
import { UsersPage } from 'pages/UsersPage/UsersPage';
import { RequireGuest } from 'shared/components/auth/RequireGuest/RequireGuest';
import { RequireAuth } from 'shared/components/auth/RequireAuth/RequireAuth';

export const App = () => {
    const {isLoading, getAccessTokenSilently} = useSystemAuth();

    // this makes the `getAccessTokenSilently` function available outside of React components
    setAccessTokenSilently(getAccessTokenSilently);

    return (
        <>
            {isLoading && (
                <div className="my-5 py-5 text-center">
                    <Spinner variant="primary"/>
                </div>
            )}
            {!isLoading && (
                <>
                    <Navbar/>
                    <Routes>
                        <Route path="/login" element={(
                            <RequireGuest redirect="/">
                                <AuthLogin/>
                            </RequireGuest>
                        )}/>
                        <Route path="/tenants/create" element={(
                            <RequireAuth>
                                <CreateTenantPage/>
                            </RequireAuth>
                        )}/>
                        <Route path="/tenants/:tenantId/update" element={(
                            <RequireAuth>
                                <UpdateTenantPage/>
                            </RequireAuth>
                        )}/>
                        <Route path="/tenants/:tenantId/users" element={(
                            <RequireAuth>
                                <UsersPage/>
                            </RequireAuth>
                        )}/>
                        <Route path="/tenants/:tenantId" element={(
                            <RequireAuth>
                                <ViewTenantPage/>
                            </RequireAuth>
                        )}/>
                        <Route path="/tenants" element={(
                            <RequireAuth>
                                <TenantsPage/>
                            </RequireAuth>
                        )}/>
                        <Route path="/" element={(
                            <RequireAuth>
                                <Dashboard/>
                            </RequireAuth>
                        )}/>
                        <Route path="*" element={(
                            <NotFoundPage>
                                <Link to="/">Return Home</Link>
                            </NotFoundPage>
                        )}/>
                    </Routes>
                </>
            )}
        </>
    );
};
