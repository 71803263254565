import React from 'react';

import { Nav, Navbar as BootstrapNavbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { useSystemAuth } from '../../shared/hooks/useAuth';
import { Config } from '../../config';
import { toTitleCase } from '../../shared/utils/string';

import { NavbarLink } from './NavbarLink';

export const Navbar = () => {
    const {isAuthenticated, signOut} = useSystemAuth();

    const logoutClicked = (e: React.MouseEvent) => {
        e.preventDefault();
        signOut();
    };

    return (
        <BootstrapNavbar>
            <BootstrapNavbar.Brand as={Link} to="/">{Config.appName}</BootstrapNavbar.Brand>
            <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav"/>
            <BootstrapNavbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    {(isAuthenticated) && (
                        <>
                            <NavbarLink to="/">{toTitleCase('my dashboard')}</NavbarLink>
                            <NavbarLink to="/tenants">Tenants</NavbarLink>
                        </>
                    )}
                </Nav>
                <Nav>
                    {(isAuthenticated) && (
                        <Nav.Link as={Link} to="/logout" onClick={logoutClicked}>Log Out</Nav.Link>
                    )}
                    {(!isAuthenticated) && (
                        <Nav.Link as={Link} to="/login">Log In</Nav.Link>
                    )}
                </Nav>
            </BootstrapNavbar.Collapse>
        </BootstrapNavbar>
    );
};
