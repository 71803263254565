import { selectorFamily } from 'recoil';

import { IDomainListQuery, IDomainListResult } from '../models';
import { readDomainList } from '../api';

export const domainListSelector = selectorFamily<IDomainListResult, IDomainListQuery>({
    key: 'domainListSelector',
    get: (domainListQuery) => async () => {
        return await readDomainList(domainListQuery);
    },
});
