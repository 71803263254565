import React from 'react';

import { Table } from 'react-bootstrap';

import { ITenant } from 'shared/modules/tenant/models';

interface ITenantDetailsProps {
    tenant: ITenant;
}

export const TenantDetails = ({tenant}: ITenantDetailsProps) => {
    return (
        <Table size="sm">
            <tbody>
            <tr>
                <td>ID</td>
                <td>{tenant.id}</td>
            </tr>
            <tr>
                <td>Name</td>
                <td>{tenant.name}</td>
            </tr>
            </tbody>
        </Table>
    );
};
