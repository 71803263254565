import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

import { Link } from 'react-router-dom';

interface IErrorMessageProps {
    children: React.ReactNode;
    returnTitle: string;
    returnTo: string;
}

export const ErrorMessage = ({children, returnTo, returnTitle}: IErrorMessageProps) => {
    return (
        <div className="ErrorMessage">
            <div className="ErrorMessage__icon-wrapper">
                <FontAwesomeIcon icon={faExclamationTriangle} size="3x"/>
            </div>
            {children}
            <div className="ErrorMessage__footer">
                Return to <Link to={returnTo}>{returnTitle}</Link>
            </div>
        </div>
    );
};

ErrorMessage.defaultProps = {
    returnTo: '/',
    returnTitle: 'Dashboard',
};
