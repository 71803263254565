import React from 'react';

import { Table } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';

import { IReadTenantsProps } from '../../shared/modules/tenant/models';
import { tenantListSelector } from '../../shared/modules/tenant/state';

import { TenantRow } from './TenantRow';

export const TenantsTable = () => {
    const [tenantFilters, setTenantFilters] = React.useState<IReadTenantsProps>({
        limit: 100,
    });
    const tenantList = useRecoilValue(tenantListSelector(tenantFilters));

    return (
        <>
            {!!tenantList.tenants.length ? (
                <Table>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Timezone</th>
                    </tr>
                    </thead>
                    <tbody>
                    {(tenantList.tenants.map(tenant => (
                        <TenantRow
                            key={tenant.id}
                            tenantId={tenant.id}
                            tenantName={tenant.name}
                            tenantTimezone={tenant.timezone}
                        />
                    )))}
                    </tbody>
                </Table>
            ) : (
                <div className="text-muted text-center">
                    There are no tenants here
                </div>
            )}
        </>
    );
};
