import React from 'react';

import {Link} from 'react-router-dom';

import {IUser} from 'shared/modules/user/models/user';
import {getFullName} from 'shared/modules/user/api/user';

interface IUserRowProps {
    user: IUser;
}

export const UserRow = ({user}: IUserRowProps) => {
    const fullName = getFullName(user);

    return (
        <tr>
            <td>
                {user.id}
            </td>
            <td>
                <Link to={`${user.id}`}>{fullName}</Link>
            </td>
            <td>
                {user.roles.join(', ')}
            </td>
            <td/>
        </tr>
    );
};
