import React from 'react';

import { Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Spinner } from 'shared/components/Spinner/Spinner';
import { TenantsTable } from './TenantsTable';
import { useTitle } from 'shared/hooks/useTitle';

export const TenantsPage = () => {
    useTitle('Tenants');

    return (
        <Container>
            <div className="mb-3">
                <h1>Tenants 2</h1>
                <Button variant="primary" as={Link} to="/tenants/create">Create Tenant</Button>
            </div>
            <React.Suspense
                fallback={(
                    <Spinner/>
                )}
            >
                <TenantsTable/>
            </React.Suspense>
        </Container>
    );
};
