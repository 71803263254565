import React from 'react';

import { Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

import { formatAxiosErrorOrThrow } from 'shared/utils/error';
import { ITenant } from 'shared/modules/tenant/models';
import { updateTenant } from 'shared/modules/tenant/api';

type UpdateTenantFormData = {
    name: string;
    timezone: string;
}

interface IUpdateTenantFormProps {
    tenant: ITenant;
    onCancel?: () => void;
    onSuccess?: (tenant: ITenant) => void;
}

export const UpdateTenantForm = ({tenant, onCancel, onSuccess}: IUpdateTenantFormProps) => {
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const {register, handleSubmit} = useForm<UpdateTenantFormData>();

    const onSubmit = (formData: UpdateTenantFormData) => {
        setIsSubmitting(true);
        (async () => {
            try {
                const response = await updateTenant(tenant.id, formData);
                onSuccess?.(response);
            } catch (e) {
                toast.error(formatAxiosErrorOrThrow('Error updating settings', e));
            }
            setIsSubmitting(false);
        })();
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="UpdateTenantForm__name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                    placeholder="Name"
                    defaultValue={tenant.name}
                    disabled={isSubmitting}
                    {...register('name')}
                />
            </Form.Group>
            <Form.Group controlId="UpdateTenantForm__timezone">
                <Form.Label>Timezone</Form.Label>
                <Form.Control
                    placeholder="Select Timezone"
                    defaultValue={tenant.timezone}
                    disabled={isSubmitting}
                    {...register('timezone')}
                />
            </Form.Group>
            <Button variant="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Saving changes...' : 'Save Changes'}
            </Button>
            <Button
                variant="secondary"
                type="button"
                onClick={() => onCancel?.()}
                disabled={isSubmitting}
            >
                Cancel
            </Button>
        </Form>
    );
};
