import axios from 'axios';
import jwtDecode from 'jwt-decode';
import Cookie from 'js-cookie';
import { differenceInSeconds } from 'date-fns';

import { AuthTenantID, Scope, systemClientId } from 'shared/modules/auth/models';
import { Config } from 'config';

export interface IPasswordLoginParams {
    username: string;
    password: string;
}

export interface IAuthorizationCodeLoginParams {
    code: string;
}

interface IJwtData {
    sub: string;
    exp: number;
    scopes: string[];
}

export interface IAuthClientOptions {
    tenantId: number | null;
}

export interface IDecodeAccessTokenResult {
    userId: number | string;
    expiresAt: Date;
    scopes: Scope[];
}

interface IAuthenticateWithCredentialsProps {
    grant_type: 'password';
    username: string;
    password: string;

    [key: string]: string;
}

interface IAuthenticateWithCodeProps {
    grant_type: 'authorization_code';
    code: string;

    [key: string]: string;
}

export class AuthClient {
    public storageKey: string;
    public cookieName: string;
    public isAuthenticated: boolean;
    public headerName: string;
    public tokenExpiresAt: Date | null;
    public tenantId: AuthTenantID;
    public userId: number | string | null;
    public scopes: Scope[];
    public refreshTimeout: ReturnType<typeof setTimeout> | null;
    private accessToken: string | null;

    constructor(private options: IAuthClientOptions) {
        this.tenantId = options.tenantId;
        this.storageKey = Config.authStorageKey;
        this.cookieName = Config.authCookieName;
        this.isAuthenticated = false;
        this.headerName = 'authorization';
        this.userId = null;
        this.tokenExpiresAt = null;
        this.refreshTimeout = null;
        this.scopes = [];
        this.accessToken = null;
    }

    /**
     * Clear the current access token
     */
    clearToken(): void {

        // Remove access token from local storage
        if (typeof (Storage) !== 'undefined') {
            sessionStorage.removeItem(this.storageKey);
        }

        // Remove local variables
        this.tenantId = null;
        this.userId = null;
        this.tokenExpiresAt = null;
        this.accessToken = null;
        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
        }

        // Disable api calls
        this.isAuthenticated = false;
    }

    async authenticate(params: IAuthenticateWithCredentialsProps | IAuthenticateWithCodeProps): Promise<string> {
        const url = `${Config.userServiceURL}/token/`;

        const formData = new FormData();
        formData.append('client_id', this.tenantId ? this.tenantId.toString() : systemClientId);
        Object.keys(params).forEach(key => formData.append(key, params[key]));

        const response = await axios.post(url, formData, {
            withCredentials: true,
        });
        const accessToken = response.data.access_token;
        const {userId, expiresAt, scopes} = this.decodeAccessToken(accessToken);
        this.userId = userId;
        this.scopes = scopes;
        this.tokenExpiresAt = expiresAt;
        this.accessToken = accessToken;
        return accessToken;
    }

    async authenticateWithCode({code}: IAuthorizationCodeLoginParams): Promise<string> {
        return await this.authenticate({
            grant_type: 'authorization_code',
            code,
        });
    }

    async authenticateWithCredentials({username, password}: IPasswordLoginParams): Promise<string> {
        return await this.authenticate({
            grant_type: 'password',
            username,
            password,
        });
    }

    async getAccessTokenSilently(): Promise<string> {
        if (!this.readIsAuthenticatedCookie()) {
            this.accessToken = null;
            this.userId = null;
            this.scopes = [];
            this.isAuthenticated = false;
            throw new Error('You need to sign in again');
        }
        if (this.accessToken && this.tokenExpiresAt && differenceInSeconds(this.tokenExpiresAt, new Date()) > 30) {
            // TODO: Check whether requested scopes differ from current scopes. If they differ then don't use the
            //  cached token
            return this.accessToken;
        }

        const formData = new FormData();
        formData.append('grant_type', 'refresh_token');
        formData.append('client_id', this.tenantId ? this.tenantId.toString() : systemClientId);

        const response = await axios.post(
            `${Config.userServiceURL}/token/`,
            formData,
            {
                withCredentials: true,
            },
        );
        const accessToken = response.data.access_token;
        const {userId, expiresAt, scopes} = this.decodeAccessToken(accessToken);
        this.accessToken = accessToken;
        this.userId = userId;
        this.scopes = scopes;
        this.tokenExpiresAt = expiresAt;
        return accessToken;
    }

    async signOut(): Promise<void> {
        await axios.get(`${Config.userServiceURL}/logout/`, {
            withCredentials: true,
        });
        this.accessToken = null;
        this.isAuthenticated = false;
        this.userId = null;
        this.scopes = [];
    }

    private readIsAuthenticatedCookie(): boolean {
        const cookie = Cookie.get(this.cookieName);
        return !!cookie;
    }

    private decodeAccessToken(accessToken: string): IDecodeAccessTokenResult {
        const tokenData: IJwtData = jwtDecode(accessToken);
        const userIdInt = parseInt(tokenData.sub);
        return {
            userId: isNaN(userIdInt) ? tokenData.sub : userIdInt,
            expiresAt: new Date(tokenData.exp * 1000),
            scopes: tokenData.scopes.map(scope => scope as Scope),
        };
    }
}
