import React from 'react';

import * as Yup from 'yup';
import { Button, Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import { IDomainCreate } from '../../shared/modules/domain/models';
import { createDomain } from '../../shared/modules/domain/api';
import { getAxiosErrorOrThrow } from '../../shared/utils/error';

interface ICreateTenantDomainModalProps {
    tenantId: number;
    show: boolean;
    onHide: () => void;
}

const createDomainForm = Yup.object().shape({
    host: Yup.string().required(),
});

export const CreateTenantDomainModal = ({tenantId, onHide, show}: ICreateTenantDomainModalProps) => {
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const {register, handleSubmit} = useForm<IDomainCreate>({
        resolver: yupResolver(createDomainForm),
        defaultValues: {
            host: '',
        },
    });

    const onSubmit = (formData: IDomainCreate) => {
        setIsSubmitting(true);
        (async () => {
            try {
                await createDomain({
                    tenantId,
                    host: formData.host,
                });
                onHide();
                toast.success('Domain created successfully! Please refresh the page.');
            } catch (e) {
                toast.error(getAxiosErrorOrThrow(e));
            } finally {
                setIsSubmitting(false);
            }
        })();
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header>
                <Modal.Title>Create Domain</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>
                            Host
                        </Form.Label>
                        <Form.Control
                            disabled={isSubmitting}
                            {...register('host')}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="primary" disabled={isSubmitting}>
                        Create Domain
                    </Button>
                    <Button variant="secondary" onClick={onHide} disabled={isSubmitting}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};