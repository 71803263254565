import React from 'react';

import { useParams } from 'react-router';
import { Container } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useRecoilStateLoadable } from 'recoil';

import { tenantSelector } from '../../shared/modules/tenant/state';
import { useTitle } from '../../shared/hooks/useTitle';
import { ITenant } from '../../shared/modules/tenant/models';

import { UpdateTenantForm } from './UpdateTenantForm';
import { ErrorMessage } from 'components/ErrorMessage/ErrorMessage';

export const UpdateTenantPage = () => {
    useTitle('Tenant Settings');

    const {tenantId: tenantIdPath} = useParams<'tenantId'>();
    const tenantId = parseInt(tenantIdPath as string);

    const [tenantLoadable, setTenant] = useRecoilStateLoadable(tenantSelector(tenantId));
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const navigate = useNavigate();

    const tenant = tenantLoadable.state === 'hasValue' ? tenantLoadable.contents : undefined;
    const error = tenantLoadable.state === 'hasError' ? tenantLoadable.errorMaybe : undefined;
    const loading = tenantLoadable.state === 'loading';
    const errorMessage = tenantLoadable?.errorMaybe?.toString();

    const onSuccess = (tenantResponse: ITenant) => {
        setTenant(tenantResponse);
    };

    React.useEffect(() => {
        if (error) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    React.useEffect(() => {
        if (!loading) {
            setIsLoading(false);
        }
    }, [loading]);

    return (
        <Container>
            <h1>Tenant Settings</h1>
            <p className="text-muted">
                Update the account settings for {(!isLoading && !error && tenant) ? tenant.name : 'a tenant'}.
            </p>
            {(!isLoading && tenant && !error) && (
                <UpdateTenantForm
                    tenant={tenant}
                    onCancel={() => navigate('/tenants')}
                    onSuccess={onSuccess}
                />
            )}
            {(error) && (
                <ErrorMessage returnTo="/tenants" returnTitle="Tenants">
                    {errorMessage}
                </ErrorMessage>
            )}
        </Container>
    );
};
