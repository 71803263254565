const readConfigEnvMaybe = (key: string): string | undefined => {
    if (key in process.env && process.env[key]) {
        return process.env[key] as string;
    } else {
        return undefined;
    }
};

const readConfigEnv = (key: string): string => {
    const envValue = readConfigEnvMaybe(key);
    if (envValue) {
        return envValue;
    } else {
        throw new Error(`Missing environment variable ${key}`);
    }
};

const readConfigNumber = (key: string): number => {
    return parseInt(readConfigEnv(key));
};

export const Config = {
    appName: readConfigEnv('REACT_APP_NAME'),
    publicHost: readConfigEnv('REACT_APP_PUBLIC_HOST'),
    authStorageKey: readConfigEnv('REACT_APP_AUTH_STORAGE_KEY'),
    authCookieName: readConfigEnv('REACT_APP_AUTH_COOKIE_NAME'),
    tenantId: readConfigEnvMaybe('REACT_APP_TENANT_ID'),
    passwordLengthMin: readConfigNumber('REACT_APP_PASSWORD_LENGTH_MIN'),
    passwordLengthMax: readConfigNumber('REACT_APP_PASSWORD_LENGTH_MAX'),
    fileServiceURL: readConfigEnv('REACT_APP_FILE_SERVICE'),
    tenantServiceURL: readConfigEnv('REACT_APP_TENANT_SERVICE'),
    courseServiceURL: readConfigEnv('REACT_APP_COURSE_SERVICE'),
    notificationServiceURL: readConfigEnv('REACT_APP_NOTIFICATION_SERVICE'),
    userServiceURL: readConfigEnv('REACT_APP_USER_SERVICE'),
    liveSessionServiceURL: readConfigEnv('REACT_APP_LIVE_SESSION_SERVICE'),
};
