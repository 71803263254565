import React from 'react';

import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import reportWebVitals from './reportWebVitals';

import { App } from 'pages/App/App';
import { AuthProvider } from 'components/AuthProvider/AuthProvider';

import 'react-toastify/dist/ReactToastify.css';
import './styles/_main.scss';

ReactDOM.render(
    <React.StrictMode>
        <RecoilRoot>
            <div className="position-relative vh-100 d-flex flex-column">
                <AuthProvider tenantId={null}>
                    <BrowserRouter>
                        <App/>
                    </BrowserRouter>
                </AuthProvider>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </RecoilRoot>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
