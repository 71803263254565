import React from 'react';

import { Button, Container } from 'react-bootstrap';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { useTitle } from 'shared/hooks/useTitle';

import { TenantDetails } from 'components/TenantDetails/TenantDetails';
import { tenantSelector } from 'shared/modules/tenant/state';
import { DomainList } from '../../components/DomainList/DomainList';

export const ViewTenantPage = () => {
    const {tenantId: tenantIdPath} = useParams<'tenantId'>();
    const tenantId = parseInt(tenantIdPath as string);
    const tenant = useRecoilValue(tenantSelector(tenantId));

    useTitle((tenant) ? tenant.name : 'View Tenant');

    return (
        <Container>
            <h1>{tenant?.name}</h1>
            <TenantDetails tenant={tenant}/>
            <DomainList tenantId={tenant.id}/>
            <div>
                <Button as={Link} to={`/tenants/${tenantId}/update`} variant="primary">Update Settings</Button>
            </div>
            <div>
                <Button as={Link} to={`/tenants/${tenantId}/users`} variant="primary">Users</Button>
            </div>
        </Container>
    );
};
