import React from 'react';

import { Container, Table } from 'react-bootstrap';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { useParams } from 'react-router';

import { Spinner } from 'shared/components/Spinner/Spinner';
import { useTitle } from 'shared/hooks/useTitle';

import { userPaginationParamsSelector, usersPaginatedSelector } from './state';
import { UserRow } from './UserRow';
import { ErrorMessage } from 'components/ErrorMessage/ErrorMessage';

export const UsersPage = () => {
    const {tenantId: tenantIdPath} = useParams<'tenantId'>();
    const tenantId = parseInt(tenantIdPath as string);

    const [pagination, setPagination] = useRecoilState(userPaginationParamsSelector(tenantId));
    const usersPaginatedLoadable = useRecoilValueLoadable(usersPaginatedSelector(tenantId));
    useTitle('Users');

    React.useEffect(() => {
        setPagination({
            tenantId,
            limit: 20,
        });
    }, [tenantId, setPagination]);

    const isLoading = usersPaginatedLoadable.state === 'loading';
    const error = usersPaginatedLoadable.state === 'hasError' && usersPaginatedLoadable.errorMaybe();
    const usersResponse = (usersPaginatedLoadable.state === 'hasValue') ? usersPaginatedLoadable.contents : undefined;
    const users = usersResponse?.users;

    return (
        <Container>
            <h1>Users</h1>
            {(isLoading) && (
                <Spinner variant="primary"/>
            )}
            {(!isLoading && error) && (
                <ErrorMessage returnTitle="Overview" returnTo={`/tenants/${tenantId}`}>
                    There was an error loading users. {error.toString()}
                </ErrorMessage>
            )}
            {(users && !isLoading && !error) && (
                <Table>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Roles</th>
                        <th>Options</th>
                    </tr>
                    </thead>
                    <tbody>
                    {users && users.map(user => (
                        <UserRow user={user}/>
                    ))}
                    </tbody>
                </Table>
            )}
        </Container>
    );
};
