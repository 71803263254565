import { selectorFamily } from 'recoil';

import { readTenantsAsync } from '../api';
import { IReadTenantsProps, IReadTenantsResult } from '../models';

export const tenantListSelector = selectorFamily<IReadTenantsResult, IReadTenantsProps>({
    key: 'tenantListSelector',
    get: ({limit, cursor}) => async () => {
        return await readTenantsAsync({
            limit,
            cursor,
        });
    },
});