import { atomFamily, selectorFamily } from 'recoil';

import { readUserList } from 'shared/modules/user/api/user';
import { IUserListResult, IUserListQuery } from 'shared/modules/user/models/user';

export const usersPaginatedParamsAtom = atomFamily<IUserListQuery, number>({
    key: 'usersPaginatedParamsAtom',
    default: (tenantId) => {
        return {
            tenantId,
            limit: 20,
        };
    },
});

export const userPaginationParamsSelector = selectorFamily<IUserListQuery, number>({
    key: 'usersPaginatedAtom',
    get: (tenantId: number) => ({get}) => {
        return get(usersPaginatedParamsAtom(tenantId));
    },
    set: (tenantId: number) => ({get, set}, newValue) => {
        set(usersPaginatedParamsAtom(tenantId), newValue);
    },
});

export const usersPaginatedSelector = selectorFamily<IUserListResult, number>({
    key: 'usersPaginatedSelector',
    get: (tenantId: number) => async ({get}): Promise<IUserListResult> => {
        const params = get(usersPaginatedParamsAtom(tenantId));
        return readUserList(params);
    },
});
