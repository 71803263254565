import React, { useState } from 'react';

import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useNavbar } from '../../shared/hooks/useNavbar';
import { useTitle } from '../../shared/hooks/useTitle';
import { useSystemAuth } from '../../shared/hooks/useAuth';
import { getAxiosErrorOrThrow } from '../../shared/utils/error';

import { FullScreenModal } from '../../shared/components/FullScreenModal/FullScreenModal';
import { FullScreenModalTitle } from '../../shared/components/FullScreenModal/FullScreenModalTitle';
import { Spinner } from '../../shared/components/Spinner/Spinner';

interface IAuthLoginProps {
    redirect: string;
}

type AuthLoginFormData = {
    username: string;
    password: string;
};

export const AuthLogin = ({redirect}: IAuthLoginProps) => {
    useNavbar(false);
    useTitle('Log In');
    const {register, handleSubmit} = useForm<AuthLoginFormData>({
        defaultValues: {
            username: 'admin',
            password: 'password',
        },
    });
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const {authenticateWithCredentials} = useSystemAuth();
    const navigate = useNavigate();

    const onSubmit = handleSubmit(({username, password}) => {
        if (isSubmitting) {
            return;
        } else {
            setIsSubmitting(true);
        }
        (async () => {
            try {
                await authenticateWithCredentials({
                    username,
                    password,
                });
                navigate(redirect);
            } catch (error) {
                const errorMessage = getAxiosErrorOrThrow(error);
                toast.error(errorMessage);
            } finally {
                setIsSubmitting(false);
            }
        })();
    });

    return (
        <FullScreenModal size="sm" close={false}>
            <FullScreenModalTitle>Sign In</FullScreenModalTitle>
            <Form onSubmit={onSubmit}>
                <Form.Group controlId="AuthLogin__username">
                    <Form.Control
                        type="text"
                        placeholder="Email or Username"
                        disabled={isSubmitting}
                        autoFocus
                        {...register('username')}
                    />
                </Form.Group>
                <Form.Group controlId="AuthLogin__password">
                    <Form.Control
                        type="password"
                        placeholder="Password"
                        disabled={isSubmitting}
                        {...register('password')}
                    />
                </Form.Group>
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting ? 'Logging in' : 'Log In'}
                    {isSubmitting && (
                        <Spinner size="sm"/>
                    )}
                </Button>
            </Form>
        </FullScreenModal>
    );
};

AuthLogin.defaultProps = {
    redirect: '/',
};
