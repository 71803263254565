import React from 'react';
import { Link } from 'react-router-dom';

interface ITenantRowProps {
    tenantId: number;
    tenantName: string;
    tenantTimezone: string;
}

export const TenantRow = ({tenantId, tenantName, tenantTimezone}: ITenantRowProps) => {
    return (
        <tr>
            <td>{tenantId}</td>
            <td><Link to={`/tenants/${tenantId}`}>{tenantName}</Link></td>
            <td>{tenantTimezone}</td>
        </tr>
    );
};
