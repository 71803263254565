import React from 'react';

import { Nav } from 'react-bootstrap';
import { Link, matchPath, useLocation } from 'react-router-dom';

interface INavbarLinkProps {
    className?: string;
    to: string;
}

export const NavbarLink = ({children, className, to}: React.PropsWithChildren<INavbarLinkProps>) => {
    const location = useLocation();
    const isActive = !!matchPath(location.pathname, to);

    return (
        <Nav.Link className={className ? className : ''} as={Link} to={to} active={isActive}>{children}</Nav.Link>
    );
};
